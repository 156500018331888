import Image from 'common/components/Image';
import { communityIcons, navMenu } from 'common/constants/constants';
import { Link } from 'gatsby';
import React from 'react';
import SocialMediaIcon from 'common/components/SocialMediaIcon';
import MenuItem from 'common/components/MenuItem';
import Container from 'common/components/UI/Container';

const Footer: React.FC = () => {
  return (
    <Container marginBottom='0'>
      <div className="flex flex-row items-center justify-between h-[152px] footer">
        <div className="flex items-center">
          <Link to="/">
            <Image
              className="image-ichi"
              alt="ICHI"
              height="40px"
              width="auto"
            />
          </Link>
        </div>
        <div className="flex">
          <div className="flex flex-row items-center primary-text-color">

            {communityIcons.map((i) => (
              <SocialMediaIcon 
                key={i.name}
                url={i.url}
                imageSrc={`../images/${i.logo}`}
                title={i.name} />
            ))}
          </div>
        </div>
      </div>
    </Container>
  );
};

export default Footer;
