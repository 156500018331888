import Image from 'common/components/Image';
import React from 'react';
import { ProSidebar, SidebarHeader, Menu, MenuItem } from 'react-pro-sidebar';
import { Link } from 'gatsby';
import { navMenu } from 'common/constants/constants';
import { OutboundLink } from 'gatsby-plugin-google-gtag';
import ThemeSwitcher from 'common/components/ThemeSwitcher';
import Button from 'common/components/Button';

type Props = {
    sidebarClassName: string;
    showSidebar: Function;
    themeToggle?: Function;
}

const SidebarSection: React.FC<Props> = (props) => {
  return (
    <ProSidebar className={props.sidebarClassName}>
    <SidebarHeader className='flex justify-between items-center'>
        <div className='ml-5'>
            <Image
                className="image-ichi-logo pl-5 ml-5"
                alt="Ichi Logo"
                height="34px"
                width="auto"
            />
        </div>
        <div className='flex items-center justify-end'>
            <ThemeSwitcher onClick={props.themeToggle} className='' />
            <Image
                alt="menu"
                className="close-menu-icon"
                onClick={() => props.showSidebar(false)} />
        </div>
    </SidebarHeader>
    <Menu iconShape="square" className='items-center align-center'>
        <MenuItem >
            Home
            <Link to="/" />
        </MenuItem>
        {navMenu.map((i) => (
            <MenuItem key={i.title} className='align-center items-center justify-center'>
                {i.title}
                {i.link.includes('http') 
                    ? (<OutboundLink href={i.link} target='blank' />)
                    : (<Link to={i.link} />)
                }
                
            </MenuItem>
        ))}
        <MenuItem  className="border rounded-full mx-10 ">
            Launch App
            <OutboundLink target="_blank" href="https://app.ichi.org" />
        </MenuItem>

    </Menu>
  </ProSidebar>         
);
};

export default SidebarSection;
