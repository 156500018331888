import { OutboundLink } from 'gatsby-plugin-google-gtag';
import React from 'react';
import useDarkMode from 'use-dark-mode';

type Props = {
  title: string;
  url: string;
  imageSrc: string;
}

const SocialMediaIcon: React.FC<Props> = (props) => {
  const darkMode = useDarkMode();
  const invert = darkMode.value ? 'invert' : '';



  return (
    <OutboundLink href={props.url} target='blank'>
      <img 
        src={props.imageSrc}
        className={`mb-0 h-5 ml-3 mr-3 md:ml-5 md:mr-0 ${invert}`} 
        alt={props.title}
        title={props.title}
      />
    </OutboundLink>
  )
};

export default SocialMediaIcon;

