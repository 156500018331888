import { gql } from '@apollo/client';

export const listDexesQuery = gql`
  query ListDexes {
    listDexes {
      items {
        id
        chainId
        dexName
        dexUrl
        isActive
        numVaults
        tvl
      }
    }
  }
`;
