import Image from 'common/components/Image';
import React from 'react';
import { Link } from 'gatsby';
import Button from 'common/components/Button';
import { navMenu } from 'common/constants/constants';
import ThemeSwitcher from 'common/components/ThemeSwitcher';
import MenuItem from 'common/components/MenuItem';
import Container from 'common/components/UI/Container';

type Props = {
  themeToggle?: Function;
  themeName?: string;
  showSidebar: Function;
}

const HeaderSection: React.FC<Props> = (props) => {
  const toggleSideBar = () => {
    // show sidebar
    props.showSidebar(true);
  }

  return (
    <Container marginBottom='0'>
      <div className="flex flex-row justify-between items-center h-24 px-5">
        <div className="flex flex-row items-center">
          <Link to="/">
            <Image
              className="image-ichi-logo"
              alt="Community"
              height="34px"
              width="auto"
            />
          </Link>
        </div>

        <div className="flex justify-end items-center">
            {navMenu.map((i) => (
              <MenuItem key={i.title} link={i.link} title={i.title} className="text-lg " />
            ))}
            <ThemeSwitcher onClick={props.themeToggle}  className="hidden lg:block" />
            
            <Link to="/amms/">
              <Button className="header-button" title="AMMs" />
            </Link>
            <div className="block lg:hidden">
              <Image
                className="menu-icon mr-0 ml-12"
                alt="menu"
                onClick={toggleSideBar} />
            </div>

        </div>
      </div>
    </Container>
  );
};

export default HeaderSection;
