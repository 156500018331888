import styled, { css } from 'styled-components';

type Props = {
  fullWidth?: string;
  noGutter?: boolean;
  mobileGutter?: boolean;
  width?: string;
  paddingTop?: string;
  paddingBottom?: string;
  marginTop?: string;
  marginBottom?: string;
};

const ContainerWrapper = styled.div<Props>`
  margin-left: auto;
  margin-right: auto;
  padding-top:  ${(props) => props.paddingTop || '0'};
  padding-bottom:  ${(props) => props.paddingBottom || '0'};
  margin-top:  ${(props) => props.marginTop || '0px'};
  margin-bottom:  ${(props) => props.marginBottom || '80px'};
  width: 100%;
  max-width: 1100px;
  padding-left: 20px;
  padding-right: 20px;
  @media (min-width: 1100px) {
    width: 100%;
  }
  @media (max-width: 768px) {
    ${(props) =>
      props.mobileGutter &&
      css`
        padding-left: 30px;
        padding-right: 30px;
      `};
  }
`;

export default ContainerWrapper;
